@import "@unioncredit/ui/src/variables";

.ProvidingTableRow {
  &__icon {
    height: 16px;
    margin-left: 4px;
  }

  .table-cell {
    &--overdue {
      color: $red500;
    }
  }
}
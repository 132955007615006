@import "@unioncredit/ui/src/variables";

.RewardStats {
  overflow: visible;

  &__bonus .NumericalBlock__value p {
    color: $violet500 !important;
  }
  &__penalty .NumericalBlock__value p {
    color: $red500 !important;
  }
}

@media screen and (max-width: $breakpoint-mobile) {
  .RewardStats {
    &__top {
      align-items: flex-start !important;
      flex-direction: column !important;

      .button {
        width: 100%;
        margin-top: 16px;
      }
    }

    &__bottom {
      margin-top: 0 !important;
      flex-wrap: wrap;

      .NumericalBlock {
        flex: 0 50%;
        margin-top: 16px;
      }
    }
  }
}
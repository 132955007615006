.NetworkSelect {
  &:not(&--connected) {
    .NetworkSelect__networks {
      filter: blur(4px);
      pointer-events: none;
    }
  }

  &__footerLink {
    text-align: center;
    margin-top: 1.5em;
    font-size: 0.8em;
    color: #373e98;
    width: 100%;

    &:hover {
      text-decoration: underline;
    }
  }
}

@import "@unioncredit/ui/src/variables";

.ProtocolData {
  .Card__header {
    &__content {
      display: flex;
      align-items: center;
    }
    &__action {
      min-width: 235px;
    }
  }

  &__TokenCard {
    padding: 16px;
    border: 0.5px solid $grey200;
    background: $grey50;
    border-radius: 16px;

    &__union {
      width: 28px;
      height: 28px;
      background: $grey100;
      padding: 6px;
      border-radius: 50%;
      margin-right: 8px;
    }

    p {
      white-space: nowrap;
    }
  }

  @media screen and (max-width: $breakpoint-mobile) {
    .Card__header {
      display: flex;
      flex-direction: column;

      &__action {
        margin-top: 8px;
      }
    }

    .grid-row:not(:last-of-type) {
      border-bottom: 0.5px solid $grey200;
    }

    .NumericalBlock {
      margin-bottom: 12px;

      &__value {
        font-size: 22px !important;
      }
    }
  }
}
@import "@unioncredit/ui/src/variables";

.DaoSegmentedControl {
  .SegmentedControl__item {
    padding-left: 35px;
    padding-right: 35px;

    @media screen and (max-width: $breakpoint-mobile) {
      font-size: 13px !important;
      line-height: 20px !important;

      svg {
        max-width: 20px !important;
        max-height: 20px !important;
      }
    }
    @media screen and (max-width: 420px) {
      font-size: 12px !important;
      line-height: 20px !important;

      svg {
        max-width: 16px !important;
        max-height: 18px !important;
      }
    }
  }
}
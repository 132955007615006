@import "@unioncredit/ui/src/variables";

.ContactsFilterControls {
  &__search {
    width: 285px;

    @media screen and (max-width: 800px) and (min-width: $breakpoint-tablet) {
      width: 250px;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }
  .button {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@import "@unioncredit/ui/src/variables";

.Register {
  &__heading {
    font-size: 24px;
    line-height: 32px;
    margin-top: 48px;

    @media screen and (max-width: $breakpoint-mobile) {
      margin-top: 24px;
    }
  }
  &__col {

    @media screen and (max-width: $breakpoint-small) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    @media screen and (max-width: $breakpoint-small) and (min-width: $breakpoint-tablet) {
      width: 75% !important;
      flex: 0 75% !important;
      max-width: none !important;
    }
    @media screen and (max-width: $breakpoint-tablet) {
      overflow: hidden;
    }
  }
  &__container {
    padding: 40px 24px;
    border-radius: 12px;
    background: $grey50;

    @media screen and (max-width: $breakpoint-mobile) {
      padding: 24px;
    }
    @media screen and (max-width: 480px) {
      padding: 16px;
    }
  }
  &__fee {
    background: white;
    border: 0.5px solid $grey200;
    border-radius: 12px;

    &-title {
      width: 100%;
      font-size: 12px;
      line-height: 16px;
      border-bottom: 0.5px solid $grey300;
      padding: 2px 12px;
      box-sizing: border-box;
      text-align: center;
    }

    &-container {
      padding: 8px 12px;

      p {
        font-size: 30px;
        line-height: 36px;
        margin-right: 4px !important;
      }
    }
  }
}

.MiniProgressListContainer {
  position: fixed;
  bottom: 24px;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  z-index: 9999;


  @media screen and (max-width: 600px) {
    display: flex;
  }
}
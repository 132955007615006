@import "@unioncredit/ui/src/variables";

.CreditStats {
  overflow: visible;

  .BorrowButton, .RepayButton {
    @media screen and (min-width: $breakpoint-mobile) {
      min-width: 200px;
    }
  }

  .PaymentReminderButton {
    @media screen and (max-width: $breakpoint-mobile) {
      margin-top: 12px;
    }
    @media screen and (min-width: $breakpoint-mobile) {
      &:hover {
        background: $grey100;
      }
    }
  }

  .Card__footer {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @media screen and (max-width: $breakpoint-mobile) {
      align-items: flex-start !important;
      flex-direction: column !important;
    }

    .button {
      @media screen and (max-width: $breakpoint-mobile) {
        width: 100%;
      }
    }
  }

  &__Legend {
    margin-right: 24px;

    @media screen and (max-width: $breakpoint-micro) {
      margin-right: 8px;

      &:last-of-type .Tooltip__content {
        left: -350%;
      }

      svg {
        width: 10px;
      }
      .Dot {
        width: 8px !important;
        height: 8px !important;
      }
    }
  }

  &__BorrowStats {

    h3 {
      @media screen and (max-width: $breakpoint-micro) {
        font-size: 14px;
      }
    }

    .NumericalBlock {
      &:not(:first-of-type) {
        @media screen and (max-width: $breakpoint-micro) {
          margin-top: 8px !important;
        }
      }

      .NumericalBlock__value {
        margin: 0 !important;
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}

.CreditStats--overdue {
  .Card__footer {
    background: $red50;

    button {
      color: white;
      background: $red500;
      margin-top: 0 !important;

      path {
        fill: white;
      }

      &:hover {
        background: $red400;
      }
      &:active {
        background: $red600;
      }
    }

    .PaymentDueInfo p, .MaxOverdueNotice {
      color: $red600 !important;
    }

    .PaymentDueInfo path {
      fill: $red600;
    }
  }
}
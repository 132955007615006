@import "src/variables";
@import "@unioncredit/ui/src/variables";

.VouchersStep {
  &__container {
    padding: 24px;
    border-radius: 12px;
    background: $grey50;

    @media screen and (max-width: $breakpoint-mobile) {
      padding: 0;
      background: white;
    }
  }
  &__card {
    border-radius: 12px;
  }
}

.VouchersStep__table {
  td:first-child,
  th:first-of-type {
    padding: 0 8px;
  }
  td:last-child,
  th:last-of-type {
    padding-right: 16px;
  }
  .table-head,
  .table-cell {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
  }
}
.VouchesStep__buttons {
  a {
    @media screen and (max-width: 360px) {
      padding: 12px 6px !important;
    }

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
@import "@unioncredit/ui/src/variables";

.ManageContactModal {
  overflow: visible;
  max-height: calc(100% - 75px) !important;

  .modalHeader {
    position: relative;

    @media screen and (max-width: 400px) {
      padding: 26px 12px
    }
  }

  .Card__body {
    padding: 0;
  }

  .container {
    padding: 24px;

    &--no-vertical {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__navigation {
    position: absolute;
    top: -8px;
    left: 0;
    padding: 8px;
    transform: translateY(-100%);

    p {
      color: white;
      font-weight: 500;
      height: 32px;
      padding: 6px 12px;
      background: #5c5958;
      margin: 0;
      box-sizing: border-box;
      border-radius: 16px;
      font-size: 14px;
      line-height: 20px;
    }

    .button {
      width: 32px;
      height: 32px;
      padding: 5px !important;
      border-radius: 50%;
      background: rgba(41, 37, 36, 0.75);

      svg {
        width: 100%;
        max-width: 24px;
        max-height: 24px;
      }

      &:hover {
        background: rgba(41, 37, 36, 0.9);
      }

      &:active {
        background: rgba(41, 37, 36, 1);
      }
    }

    .button + * {
      margin-left: 4px;
    }
  }

  &__tabs {
    height: 48px;
    padding: 0;
    border-radius: 0;
    background: white;
    border-left: none;
    border-right: none;

    .SegmentedControl__wrap {
      height: 100%;
    }
    .SegmentedControl__item {
      margin: 0;

      &--slider {
        height: 2px;
        box-sizing: border-box;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $blue500 !important;
        border-radius: 0;
        padding: 0;
        top: auto;
        bottom: 0;
        z-index: 2;
        width: 50% !important;
      }

      &:not(.SegmentedControl__item--slider) {
        border-radius: 0;

        &:hover {
          background: white;
        }
      }
    }
  }
}


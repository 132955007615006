.TransactionHistory {
  &__empty {
    min-height: 250px;

    .empty-state {
      width: 100%;
      margin: 15px;
    }
  }

  .avatarIcon {
    position: relative;
    svg {
      position: absolute;
      bottom: -4px;
      left: -4px;
    }
  }

  .external-inline {
    width: 7px;
    display: inline-block;
    vertical-align: middle;
    position: relative;

    path {
      fill: #a8a29e;
    }
  }

  a:hover .external-inline path {
    fill: #3b82f6;
  }
}

@import "@unioncredit/ui/src/variables";

.ProfileSidebar {
  &__Balances {
    .Stats {
      @media screen and (max-width: $breakpoint-mobile) {
        flex-direction: column !important;

        .NumericalBlock:not(:first-of-type) {
          margin-top: 12px;
        }
        .NumericalBlock__value {
          margin: 0 !important;
        }
      }
    }
  }
}
@import "@unioncredit/ui/src/variables";

.ProfileInner {
  margin: 0 auto;
  max-width: 900px;

  .Card, .Card__body {
    overflow: visible;
  }

  .WriteOffButton {
    background: $red500;

    &:hover {
      background: $red600;
    }
  }
}

@import "@unioncredit/ui/src/variables";

.MobileColumnToggle {
  .Card__body {
    padding: 12px !important;
  }
  .modalHeader {
    height: auto !important;
    padding: 16px 24px !important;
  }
  &__toggle {
    height: 32px;
    font-size: 12px;
    line-height: 24px;
    padding: 6px 8px;
  }

  .control-group {
    & > .box {
      margin: 0 !important;
    }

    .box, .Control__wrapper {
      width: 100%;
    }

    .Control--checkbox,
    .Control--radio {
      display: none;
    }
    .Control--checkbox + .Control__label,
    .Control--radio + .Control__label {
      width: 100%;
      margin: 0 !important;
      padding: 8px;
      border-radius: 8px;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      color: $grey700;
      font-weight: 500;
    }
    .Control--checkbox.Control--checked + .Control__label,
    .Control--radio.Control--checked + .Control__label {
      background: $blue100;
      color: $blue600;
    }
  }

  &__columns {
    .Control--checked + .Control__label {
      position: relative;

      &::after {
        content: ' ';
        display: block;
        width: 24px;
        height: 24px;
        background: url("../../../../public/images/CheckIcon.svg");
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
@import "@unioncredit/ui/src/variables";

.ContactDetailsTab {
  &__stats {
    margin: 0 -4px !important;
    
    @media screen and (max-width: $breakpoint-mobile) {
      flex-direction: column !important;
    }
  }
  &__stat {
    margin: 0 4px;

    &:not(:last-of-type) {
      @media screen and (max-width: $breakpoint-mobile) {
        margin-bottom: 8px;
      }
    }
  }
  .NumericalRows {
    p {
      font-weight: 500;
    }
  }
}
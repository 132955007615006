@import "@unioncredit/ui/src/variables";

.ContactList {
  .table {
    overflow: visible;
  }

  .table-head {
    color: $grey700;
  }

  .SegmentedControl {
    border: 0.5px solid $grey200;
  }

  .SegmentedControl__item--slider {
    top: -1px;
    height: 41px;
    box-shadow: 0 0 1px 0.5px #0000001A, 0 4px 8px 0 #00000026, 0 0 4px 0 #0000001A;
  }
}

@media screen and (max-width: $breakpoint-tablet) {
  .ContactList {
    &__header {
      padding: 16px !important;
      flex-direction: column !important;

      .SegmentedControl {
        width: 100%;
        margin-bottom: 8px;
      }

      input {
        height: 40px;
        font-size: 14px;
      }

      .button {
        &:not(.ResetAccountStatusButton) {
          height: 40px;
          padding-left: 8px !important;
          padding-right: 8px !important;
          font-size: 14px !important;
        }

        &--noLabel {
          width: 40px;
          padding: 0 !important;

          svg {
            width: 20px;
          }
        }
      }
    }

    .table-head {
      padding: 3px 4px;
    }

    .table-head:first-of-type,
    .table-cell:first-of-type {
      padding-left: 16px;
    }

    .table-head:last-of-type,
    .table-cell:last-of-type {
      padding-right: 16px;
    }
  }
}
@import "@unioncredit/ui/src/variables";

.AddressSummary {
  &__ens {
    line-height: 26px !important;
  }
  &__info {
    .badge.badge--grey {
      border: 1px solid $grey200;
    }

    @media screen and (max-width: 370px) {
      flex-direction: column !important;
      align-items: flex-start !important;

      .badgeRow {
        margin-bottom: 4px;
      }
    }
  }
  &__alias-input {
    width: 70%;
    padding: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 4px;
    border: none;
    color: #44403c;
    letter-spacing: -0.02em;
    font-family: "Inter", sans-serif;
    min-width: 0;
    margin-right: 8px;
  }
}
@import "@unioncredit/ui/src/variables";

.NetworkSelectOption {
  cursor: pointer;
  border-radius: 12px;

  &--active {
    background: $blue50;
    border-color: $blue600;

    .NetworkSelectOption__contentBox {
      border-color: $blue200;
    }
  }

  &:not(.NetworkSelectOption--active):hover {
    background: $grey50;
    border-color: $grey300;
  }

  &--disabled {
    opacity: 0.4;
  }

  &__check {
    width: 8px;
    margin-right: 4px;

    path {
      fill: $blue600;
    }
  }
}

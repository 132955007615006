@import "../../variables.scss";

.ConnectBanner {
  height: 176px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../images/gradient.png");
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  position: relative;
  background-size: cover;
  background-position: center;

  &__bold {
    font-weight: 500;
  }

  @media screen and (max-width: $mobileWidth) {
    height: 120px;
  }

  .heading.heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;

    @media screen and (max-width: $mobileWidth) {
      font-size: 16px;
      line-height: 18px;
    }
  }

  &__innerContainer {
    width: 420px;

    @media screen and (max-width: $mobileWidth) {
      width: 100%;
      max-width: 420px;
    }
  }
}
